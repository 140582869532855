import {useContext, useEffect, useState} from "react";
import AuthContext from "../contexts/AuthContext";
import AuthAPI from "../services/AuthAPI";
import {
    SIDE_MENU_MES_COMMANDES,
    SIDE_MENU_MON_FLORAJET
} from "../constants/SideMenuConstants";
import {Store} from "../providers/MenuProvider";

export function sideMenuManager() {
    const {user} = useContext(AuthContext);
    const [isAdmin] = useState(AuthAPI.isAdministrator(user))

    const {state, dispatch, toggleMenu} = useContext(Store);

    const handleModalShow = (showModal) => {
        toggleMenu()
        dispatch({
            type: 'HANDLE_MODAL',
            modalShow: showModal,
        });
    }

    const handleNavItem = () => {
        toggleMenu()
        // handleAccordion()
    }

    // Préremplie le champ du code fleuriste
    // du formulaire de contact.
    function contactFormOnMount(idModal) {
        document.getElementById(idModal).addEventListener('DOMNodeInserted', function (event) {
            if (document.getElementById("fld_CS_MemberCode") !== null) {
                document.getElementById("fld_CS_MemberCode").value = user.CodeFl;
            }
        });
    }

    function handleDefaultKeys(
      accordionDefaultKey = SIDE_MENU_MON_FLORAJET.eventKey,
      subAccordionDefaultKey = SIDE_MENU_MES_COMMANDES.eventKey
    ) {
        dispatch({
            type: 'HANDLE_DEFAULT_KEYS',
            accordionDefaultKey,
            subAccordionDefaultKey,
        })
    }


    return {
        state,
        dispatch,
        toggleMenu,
        isAdmin,
        handleNavItem,
        handleModalShow,
        handleDefaultKeys,
        contactFormOnMount,
        user
    }
}
